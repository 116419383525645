@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'isidora_sans_boldbold';
  src: url('../public/webfontkit/isidorasans-bold-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'isidora_sans_bold_italicBdIt';
  src: url('../public/webfontkit/isidorasans-bolditalic-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'isidora_sansbold';
  src: url('../public/webfontkit/isidorasans-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #040d11;
  color: white;
}

a{
  text-decoration: none;
  color: inherit;
}

h1, h2, h3{
  color: rgb(247, 247, 247);
  font-family: "isidora_sansbold";
}

a.active{
  color: #1649ff;
  position: relative;
}

a.active::after{
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #1649ff;
  border-radius: 5px;
  animation: animate .8s linear;
}

@keyframes animate{
  0%{
    width: 0%;
  }
  40%{
    width: 100%;
  }
  60%{
    width: 90%;
  }
  100%{
    width: 100%;
  }
}

.skiptranslate iframe{
  display: none !important;
}

#google_translate_element{
  height: 35px;
  overflow: hidden;
  border-radius: 5px;
}

#google_translate_element select{
  padding: 5px 0 5px 10px;
  background: transparent;
  border: none;
  font-weight: 1.1rem;
  font-family: sans-serif;
}

#google_translate_element select option{
  color: black;
}

#google_translate_element select:first-child{
  color: white;
}

#google_translate_element span{
  display: none;
}