.container{
  width: 100%;
  background: #000000;
  padding: 30px 20px;
}

.text{
  font-size: 1rem;
  color: rgba(240, 248, 255, 0.362);
  font-family: "isidora_sansbold";
}

.term{
  font-size: .85rem;
  margin-bottom: 30px;
  color: white;
  font-weight: 200;
  line-height: 2;
}