.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}

.title{
  font-size: 3rem;
  margin-bottom: 15px;
  text-shadow: 0 0 3px rgba(4, 0, 53, 0.5);
  position: relative;
  padding-bottom: 15px;
}


.title::after{
  content: '';
  width: 50px;
  padding: 2.5px;
  border-radius: 3px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  filter: drop-shadow(0 0 3px rgba(4, 0, 53, 0.5));
}

.subtitle{
  font-size: .8rem;
  margin-bottom: 15px;
  color: rgb(156, 156, 156);
}

.testimonials{
  width: 100%;
  display: flex;
  justify-content: center;
}

.testimonial{
  width: 80%;
  height: fit-content;
  display: flex;
  background-color: rgba(113, 113, 113, 0.1) !important;
  backdrop-filter: blur(20px);
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  padding: 30px 20px;
  margin: 30px 0;
}

.testimonial:hover{
  transform: scale(1.05);
}

.testimonialInfo h3{
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: white;
}

.testimonialInfo p{
  font-size: 1rem;
  font-weight: 100;
  margin-bottom: 20px;
  color: rgb(231, 231, 231);
}

.testimonial img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.social{
  display: flex;
  align-items: center;
  gap: 5px;
}

.social a{
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 11, 16);
  font-size: .9rem;
  transition: all 0.3s ease;
  background: rgb(245, 252, 255);
  border-radius: 50%;
}

.social a:hover{
  background: rgb(0, 11, 16);
  color: rgb(245, 252, 255);
}



@media screen and (max-width: 850px){
.testimonial{
  width: 250px;
}
}


@media screen and (max-width: 600px){
.container{
  padding: 0 20px;
}

.title{
  font-size: 9vw;
}

.title::after{
  width: 15vw;
  padding: .3vw;
}

.remark{
  font-weight: 100;
}

.testimonial{
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.testimonial img{
  width: 40px;
  height: 40px;
}

}