.container{
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-color: rgba(62, 62, 62, 0.2) ;
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation-name: slideDown;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  transition: all ease .7s;
}

@keyframes slideDown {
  0% {  margin-top: -100%; }
}

.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.header h3{
  color: rgb(55, 255, 0);
  font-weight: 600;
}

.withdrawal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.withdrawal p{
  color: rgba(255, 255, 255, 0.836);
  font-size: .9rem;
}