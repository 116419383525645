.container{
  min-height: 100vh;
  background: whitesmoke;
  padding: 100px 0 0 0;
}

.form{
  width: 450px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05);
}

.form h1{
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
  color: #121212;
}

.form input{
  height: 18px;
}

.btn{
  width: 100%;
  padding: 17px 0;
  background: #121212;
  color: rgb(240, 240, 240);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all .3s ease;
}

.btn:hover{
  background: #1649ff;
}

.link{
  text-align: center;
  font-size: 0.9rem;
  color: rgb(0, 36, 36);
  transition: all .3s ease;
}

.link:hover{
  color: rgb(40, 185, 124);
}

.success{
  color: rgb(40, 185, 124);
  font-size: 0.9rem;
  word-wrap: break-word;
}

.error{
  color: rgb(247, 45, 45);
  font-size: 0.9rem;
  word-wrap: break-word;
}


@media screen and (max-width: 600px){
  .container{
    background: white;
  }
  .form{
    width: 90%;
    background: white;
  }
}