.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
}

.title{
  font-size: 3rem;
  padding: 15px 0;
  text-shadow: 0 0 3px rgba(4, 0, 53, 0.5);
  position: relative;
}

.title::after{
  content: '';
  width: 50px;
  padding: 2.5px;
  border-radius: 3px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255);
  filter: drop-shadow(0 0 3px rgba(6, 0, 67, 0.5));
}

.subtitle{
  text-align: center;
  font-size: 1rem;
  padding: 15px 0;
  font-family: 'isidora_sansbold';
}

.cardContainer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.card{
  width: 22vw;
  padding: 30px 20px;
  margin: 20px 0;
  border-radius: 20px;
  background-color: rgba(113, 113, 113, 0.1);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
  transition: all 0.3s ease-in-out;
}

.card:hover{
  margin-top: -2px;
  background-color: rgba(113, 113, 113, 0.2);
}

.card img{
  width: 70px
}

.card h3{
  font-size: 2rem;
  padding: 15px 0;
  color: white;
}

.card p{
  font-size: 1rem;
  padding: 15px 0;
  font-weight: 300;
  color: white;
}


@media screen and (max-width: 1100px){
  .container{
    padding: 0 20px;
  }
}


@media screen and (max-width: 900px){
.container{
  padding: 0 10px;
}

.cardContainer{
  justify-content: center;
  gap: 20px;
}

.card{
  width: 45vh;
  margin-bottom: 0px;
}
}

@media screen and (max-width: 650px){
.card{
  width: 100%;
}

.title{
  font-size: 9vw;
}

.title::after{
  width: 15vw;
  padding: .3vw;
}

}