.container{
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 50px;
  margin-top: -50px;
}

.left{
  width: 48%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.left h1{
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}

.left p{
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.6);
}

.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.button1{
  padding: 10px 20px;
  border-radius: 35px;
  background: #1649ff;
  font-size: .9rem;
  font-weight: 500;
  color: white;
  transition: all 0.3s ease;
}

.button2{
  color: whitesmoke;
  font-size: .9rem;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.button2:hover{
  color: #1649ff;
  cursor: pointer;
}

.button1:hover{
  background: white;
  color: #000000;
  cursor: pointer;
}

.right{
  width: 45%;
  height: 80%;
}

.right2{
  width: 45%;
  height: 85%;
}

.right img{
  width: 100%;
  animation: upDown 1.5s linear infinite alternate-reverse;
}

.right2 img{
  width: 85%;
  animation: upDown 1.5s linear infinite alternate-reverse;
}

@keyframes upDown {
  from {margin-top: -7px;}
  to {margin-bottom: -7px;}
}

@media screen and (max-width: 1100px){
.container{
  padding: 0 20px;
}

.left{
  width: 50%;
}

.left h1{
  font-size: 6vw;
}

.left p{
  font-size: 1.5vw;
}

.right img{
  width: 100%;
  padding-top: 50px;
}

.right2 img{
  width: 95%;
  padding-top: 50px;
}
}



@media screen and (max-width: 950px){
  
.right img, .right2 img{
  padding-top: 90px;
}
}


@media screen and (max-width: 800px){
.right, .right2{
  display: none;
}

.left{
  width: 100%;
  padding-right: 50px;
}

.left h1{
  font-size: 9vw;
}

.left p{
  font-size: 2.5vw;
}
}


@media screen and (max-width: 500px){
.container{
  height: fit-content;
  padding: 150px 20px 50px 20px;
}


.left{
  padding-right: 0;
}

.left h1{
  font-size: 2.5rem;
}

.left p{
  font-size: 1rem;
  padding: 10px 0;
}

.button1{
  padding: 11px 30px;
}
}