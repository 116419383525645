.container{
  position: fixed;
  top: 0;
  right: 0;
  padding: 80px 20px 0 0;
  animation-name: slideIn;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  transition: all ease .3s;
}

.card{
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

@keyframes slideIn {
  0%{ margin-right: -100vw;}
  60%{ margin-right: -100vw;}
  70%{ margin-right: -0vw; display: none;}
  100%{margin-right: -0vw; display: none;}
}

.card h3{
  color: rgb(4, 145, 40);
  font-size: 1.6rem;
  font-weight: 700;
}

.card p{
  font-size: .9rem;
  font-weight: 200;
}