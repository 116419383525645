.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 70px;
}

.title{
  font-size: 3rem;
  padding: 15px 0;
  text-shadow: 0 0 3px rgba(4, 0, 53, 0.5);
  position: relative;
  margin-bottom: 100px;
}

.title::after{
  content: '';
  width: 50px;
  padding: 2.5px;
  border-radius: 3px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255);
  filter: drop-shadow(0 0 3px rgba(4, 0, 53, 0.5));
}

.about{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.left h1{
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: whitesmoke;
}

.left p{
  font-size: .85rem;
  line-height: 1.8;
  color: whitesmoke;
}

.right{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right img{
  width: 80%;
}

@media screen and (max-width: 850px){
.container{
  padding: 30px 100px;
}

.title{
  font-size: 7vw;
}

.title::after{
  width: 15vw;
  padding: .3vw;
}

.about{
  flex-direction: column;
}

.left{
  width: 100%;
}

.right{
  width: 100%;
  margin-top: 30px;
}

.right img{
  width: 100%;
}

}


@media screen and (max-width: 550px){
.container{
  padding: 30px 20px;
  align-items: flex-start;
}

.title{
  margin-bottom: 10px;
}

.title::after{
  display: none;
}

.left h1{
  display: none;
}
}