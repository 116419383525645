.container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
}

.left{
  width: 45%;
}

.right{
  width: 45%;
}

.right h1{
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 0 0 3px rgba(4, 0, 53, 0.5);
}

.subtitle{
  display: none;
}

.right p{
  font-size: .8rem;
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-family: 'isidora_sansbold';
  color: rgb(234, 234, 234);
  font-weight: 100;
}

.bars{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.barWrapper div{
  height: 11px;
  display: flex;
  justify-content: space-between;
  background: none;
}

.barWrapper div p{
  font-size: .9rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.bar{
  width: 100%;
}

.bar::-webkit-meter-bar {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.177);
}

.bar::-webkit-meter-optimum-value {
  background: #1649ff;
  border-radius: 1px;
}



@media screen and (max-width: 1100px){
.container{
  padding: 0 20px;
}

.right{
  width: 50%;
}

}


@media screen and (max-width: 900px){
.container{
  flex-direction: column-reverse;
  gap: 40px;
  margin-top: 100px;
}

.left, .right{
  width: 60%;
}

}

@media screen and (max-width: 700px){
.left, .right {
  width: 100%;
}

.right h1{
  font-size: 7vw;
}

.right p{
  font-size: 1;
  margin-bottom: 30px;
  line-height: 1.3;
}


}