.container, .container2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 0 50px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  color: white;
}

.container2{
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
}
/* 
.container2 select{
  color: black !important;
} */

.logo{
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo h2{
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

.logo img{
  width: 50px;
}

.menu{
  display: flex;
  align-items: center;
  justify-content: center;
  gap : 25px;
}

.menu a{
  font-weight: 500;
  font-size: .9rem;
}

.getStarted{
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  margin-left: 20px;
  transition: all 0.3s ease;
}

.getStarted:hover{
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.login{
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  background: #1649ff;
  color: #ffffff;
  transition: all 0.3s ease;
}

.login:hover{
  background: rgb(1, 138, 1);
  color: rgb(255, 255, 255);
}

.hamburger{
  display: none;
}
  
.bar{
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  transition: all 0.3s ease;
}

  
.activeBar{
  display: block;
  width: 25px;
  height: 4px;
  margin: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}




@media screen and (max-width: 1100px){
.container, .container2{
  padding: 0 20px;
}
}

@media screen and (max-width: 800px){
  
.logo img{
  width: 40px;
}

.logo h2{
  font-size: 1rem;
  margin-left: -5px;
}
.menu{
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 80vw;
  background: white;
  color: black;
  padding-top: 40px;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  z-index: 10000;
  transition: all ease-in-out .5s;
  font-size: 1rem;
}


.hamburger{
  display: block;
  cursor: pointer;
  transition: all ease-in-out .5s;
  position: relative;
  z-index: 100000;
}

.bar{
  background: #fff !important;
  border-radius: 2px;
  transition: all ease-in-out .5s;
}

.bar:nth-child(2){
  width: 15px;
  margin-left: auto;
}

.activeBar{
  width: 22px;
  background: #080808;
  border-radius: 2px;
  transition: all ease-in-out .5s;
}

.activeBar:nth-child(1){
  transform: rotate(45deg) translate(0, 6px);
}

.activeBar:nth-child(2){
  display: none;
}

.activeBar:nth-child(3){
  transform: rotate(135deg) translate(0, 6px);
}

.getStarted{
  margin-left: 0;
}

.getStarted:hover{
  background: none;
  color: rgb(0, 103, 176);
}

.menu a:hover{
  color: rgb(0, 103, 176);
}

  
}